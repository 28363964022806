import _baseGetTag from "./_baseGetTag";
import _isObjectLike from "./isObjectLike";
var exports = {};
var baseGetTag = _baseGetTag,
  isObjectLike = _isObjectLike;

/** `Object#toString` result references. */
var regexpTag = "[object RegExp]";

/**
 * The base implementation of `_.isRegExp` without Node.js optimizations.
 *
 * @private
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a regexp, else `false`.
 */
function baseIsRegExp(value) {
  return isObjectLike(value) && baseGetTag(value) == regexpTag;
}
exports = baseIsRegExp;
export default exports;