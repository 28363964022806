import _baseIsRegExp from "./_baseIsRegExp";
import _baseUnary from "./_baseUnary";
import _nodeUtil from "./_nodeUtil";
var exports = {};
var baseIsRegExp = _baseIsRegExp,
  baseUnary = _baseUnary,
  nodeUtil = _nodeUtil;

/* Node.js helper references. */
var nodeIsRegExp = nodeUtil && nodeUtil.isRegExp;

/**
 * Checks if `value` is classified as a `RegExp` object.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a regexp, else `false`.
 * @example
 *
 * _.isRegExp(/abc/);
 * // => true
 *
 * _.isRegExp('/abc/');
 * // => false
 */
var isRegExp = nodeIsRegExp ? baseUnary(nodeIsRegExp) : baseIsRegExp;
exports = isRegExp;
export default exports;